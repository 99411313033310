import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'

export default function UpdateTask({ taskId, onClose, onUpdate }) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [pointPrice, setPointPrice] = useState('')
  const [categories, setCategories] = useState([])

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/categories`)
      .then((response) => {
        setCategories(response.data)
      })
      .catch((error) => console.error('Failed to fetch categories:', error))

    if (taskId) {
      axios
        .get(`${API_BASE_URL}/api/tasks/${taskId}`)
        .then((response) => {
          const task = response.data
          setTitle(task.title || '')
          setDescription(task.description || '')
          setCategoryId(task.categoryId || '')
          setPointPrice(task.point_price || '')
        })
        .catch((error) => console.error('Failed to fetch task:', error))
      console.log('Task ID:', taskId)
    }
  }, [taskId])

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .put(`${API_BASE_URL}/api/tasks/${taskId}`, {
        title,
        description,
        categoryId: Number(categoryId),
        point_price: pointPrice,
      })
      .then(() => {
        console.log('Task updated successfully')
        logEvent(`The task titled '${title}' was updated.`)
        onClose()
        onUpdate()
      })
      .catch((error) => {
        console.error('Failed to update task:', error)
      })
  }

  const logEvent = async (message) => {
    try {
      await axios.post(`${API_BASE_URL}/api/logs`, { message })
      console.log('Event logged successfully')
    } catch (error) {
      console.error('Error logging event:', error)
    }
  }

  return (
    <div className='bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='text-lg font-semibold leading-6 text-gray-900'>
          Update Task
        </h3>
        <form className='space-y-4' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='title'
              className='block text-sm text-left font-medium text-gray-700'>
              Title
            </label>
            <input
              type='text'
              name='title'
              id='title'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor='description'
              className='block text-sm text-left font-medium text-gray-700'>
              Description
            </label>
            <textarea
              name='description'
              id='description'
              required
              rows={4}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor='category'
              className='block text-sm text-left font-medium text-gray-700'>
              Category
            </label>
            <select
              name='category'
              id='category'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor='pointPrice'
              className='block text-sm text-left font-medium text-gray-700'>
              Points
            </label>
            <input
              type='number'
              name='pointPrice'
              id='pointPrice'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              value={pointPrice}
              onChange={(e) => setPointPrice(e.target.value)}
            />
          </div>
          <div className='flex justify-end space-x-3'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={onClose}>
              Close
            </button>
            <button
              type='submit'
              className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

//file: src/pages/tasks.js
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import Team from '../components/Team'
const KidsCards = () => {
  useEffect(() => {
    document.title = 'Kids Rewards System'
  })
  return (
    <Layout>
      <Team />
    </Layout>
  )
}
export default KidsCards

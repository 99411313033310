import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'
import UserTableTasks from './UserTableTasks'
import UserTasksList from './UserTasksList'
import { useNavigate } from 'react-router-dom'

import maryImage from '../images/mary.png'
import gregoryImage from '../images/gregory.png'
import alexImage from '../images/alex.png'

const imageMap = {
  maryImage,
  gregoryImage,
  alexImage,
}

const UserDashboard = () => {
  const [kids, setKids] = useState(null)
  const [loading, setLoading] = useState(true)
  const { kidId } = useParams()
  const [showDailyTasks, setShowDailyTasks] = useState(false)
  const [categories, setCategories] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [showTasksList, setShowTasksList] = useState(false)
  const [badges, setBadges] = useState([])

  const navigate = useNavigate()

  const toggleShowDaily = () => {
    setShowDailyTasks(!showDailyTasks)
    setSelectedCategoryId(null)
  }

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/kids`)
      .then((response) => {
        setKids(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('There was an error fetching the kids:', error)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/badges`)
      .then((response) => {
        setBadges(response.data)
      })
      .catch((error) => console.error('Error fetching badges:', error))
  }, [kidId])

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/categories`)
      .then((response) => response.json())
      .then((data) => setCategories(data))

      .catch((error) => console.error('Error fetching categories:', error))
  }, [])

  // Function to handle category click
  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(selectedCategoryId === categoryId ? null : categoryId)
    setShowDailyTasks(false)
    // You might want to fetch user tasks filtered by the selected category here
    fetch(`${API_BASE_URL}/api/usertasks?categoryId=${categoryId}`)
      .then((response) => response.json())
      .then((data) => {
        // Handle tasks data
        console.log(data)
      })
      .catch((error) =>
        console.error('Error fetching tasks for category:', error)
      )
  }

  const updateKidPoints = (newPoints) => {
    if (kids && kid) {
      setKids(
        kids.map((k) => (k._id === kidId ? { ...k, points: newPoints } : k))
      )
    }
  }

  const updateKidCompletedTasks = (newCompletedTasks) => {
    if (kids && kid) {
      setKids(
        kids.map((k) =>
          k._id === kidId ? { ...k, points: newCompletedTasks } : k
        )
      )
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  const kid = kids.find((k) => k._id === kidId)

  if (!kid) {
    return <div>Kid not found</div>
  }
  const kidImageUrl = imageMap[kid.imageUrl]

  const handleSeeAllClick = () => {
    navigate(`/badges`) // Navigate to the UserDashboard of the clicked kid
  }
  const getImageUrl = (imageId) => `${API_BASE_URL}/api/images/${imageId}`

  return (
    <>
      <div className='bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:p-6'>
          <h1 className='text-2xl text-center font-semibold leading-10 text-gray-900'>
            {kid.name}'s Dashboard
          </h1>
          <div className='max-w-7xl mx-auto sm:px-6 lg:px-8'>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/4 px-4 mb-4 md:mb-0'>
                <div className='bg-white p-6 rounded-lg shadow'>
                  <div className='flex items-center space-x-4 mb-4'>
                    <img
                      className='h-32 w-32 rounded-full'
                      src={kidImageUrl}
                      alt={`${kid.name}`}
                    />
                    <div>
                      <h1 className='font-bold text-4xl'>{kid.name}</h1>
                      <h2 className='font-bold text-xl text-red-600'>
                        Points: {kid.points}
                      </h2>
                    </div>
                  </div>
                  <button className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4 w-full'>
                    Redeem Points
                  </button>
                  <div className='flex flex-col space-y-2'>
                    <h3 className='font-bold text-lg'>Filters</h3>
                    <p
                      className={`text-gray-700 flex items-center ${
                        showDailyTasks
                          ? 'font-bold text-indigo-600'
                          : 'font-normal text-gray-700'
                      } cursor-pointer`}
                      onClick={toggleShowDaily}>
                      <i
                        className={`far ${
                          showDailyTasks ? 'fa-check-circle' : 'fa-circle'
                        } text-xs mr-2`}></i>{' '}
                      Daily
                    </p>
                  </div>
                  <div className='flex flex-col my-4 space-y-2'>
                    <h3 className='font-bold text-lg'>Categories</h3>
                    {categories.map((category) => (
                      <p
                        key={category.id}
                        className={`text-gray-700 flex items-center ${
                          selectedCategoryId === category.id
                            ? 'font-bold text-indigo-600 cursor-pointer'
                            : 'font-normal text-gray-700 cursor-pointer'
                        }`}
                        onClick={() => handleCategoryClick(category.id)}>
                        <i
                          className={`far ${
                            selectedCategoryId === category.id
                              ? 'fa-check-circle'
                              : 'fa-circle'
                          } text-xs mr-2`}></i>{' '}
                        {category.name}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 px-4'>
                <div className='bg-white p-6 rounded-lg shadow mb-4'>
                  <div className='mb-4 flex justify-between items-center'>
                    <h2 className='text-xl font-bold'>My Tasks</h2>
                    <button
                      className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mb-4'
                      onClick={() => setShowTasksList(!showTasksList)}>
                      {showTasksList ? 'View My Tasks' : 'Get More Tasks'}
                    </button>
                  </div>
                  {showTasksList ? (
                    <UserTasksList kidId={kidId} />
                  ) : (
                    <UserTableTasks
                      kidId={kidId}
                      showDailyTasks={showDailyTasks}
                      categoryId={selectedCategoryId}
                      updateKidPoints={updateKidPoints}
                      updateKidCompletedTasks={updateKidCompletedTasks}
                    />
                  )}
                </div>
              </div>
              <div className='w-full md:w-1/4 px-4'>
                <div className='bg-white p-6 rounded-lg shadow mb-4'>
                  <div className='mb-4 flex justify-between items-center'>
                    <h2 className='text-xl font-bold'>Badges</h2>
                    <button className='text-gray-600'>
                      <i className='fas fa-ellipsis-h'></i>
                    </button>
                  </div>
                  <div className='grid grid-cols-2 gap-2'>
                    {badges
                      .filter((badge) => kid && kid.points >= badge.pointsToGet)
                      .sort((a, b) => a.pointsToGet - b.pointsToGet)
                      .map((badge) => (
                        <img
                          key={badge._id}
                          className='h-24 w-24'
                          src={getImageUrl(badge.imageId)}
                          alt={`Badge ${badge.name}`}
                        />
                      ))}
                  </div>
                  <button
                    className='text-blue-600 hover:text-blue-700 font-semibold py-2 mt-4'
                    onClick={handleSeeAllClick}>
                    See all
                  </button>
                </div>
                <div className='bg-white p-6 rounded-lg shadow'>
                  <div className='mb-4 flex justify-between items-center'>
                    <h2 className='text-xl font-bold'>Progress</h2>
                    <button className='text-gray-600'>
                      <i className='fas fa-ellipsis-h'></i>
                    </button>
                  </div>
                  <div className='text-xl mt-4'>
                    <h2>Completed Tasks: {kid.completedTasks}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDashboard

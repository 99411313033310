//file: src/pages/tasks.js
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import BadgesItems from '../components/BadgesItems'
const BadgesList = () => {
  useEffect(() => {
    document.title = 'Badges'
  })
  return (
    <Layout>
      <BadgesItems />
    </Layout>
  )
}
export default BadgesList

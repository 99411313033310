// UserTableTasks.jsx
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UserTask from './UserTask'
import { API_BASE_URL } from '../apiConfig'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const UserTableTasks = ({
  kidId,
  showDailyTasks,
  categoryId,
  updateKidPoints,
  updateKidCompletedTasks,
}) => {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [taskToDelete, setTaskToDelete] = useState(null)

  const openDeleteModal = (taskId) => {
    setTaskToDelete(taskId)
    setIsDeleteModalOpen(true)
  }

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/usertasks/${kidId}`
        )
        let filteredTasks = response.data

        // Apply daily filter
        if (showDailyTasks) {
          filteredTasks = filteredTasks.filter((task) => task.daily)
        }

        // Apply category filter
        if (categoryId) {
          filteredTasks = filteredTasks.filter(
            (task) => task.taskId.categoryId === categoryId
          )
        }

        setTasks(filteredTasks)
      } catch (error) {
        console.error('Failed to fetch tasks:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchTasks()
  }, [kidId, showDailyTasks, categoryId])

  const handleToggleComplete = async (task) => {
    const taskId = task._id
    const newIsCompleteStatus = !task.isComplete

    // Toggle the completion status optimistically in the UI
    setTasks((prevTasks) =>
      prevTasks.map((t) =>
        t._id === taskId ? { ...t, isComplete: newIsCompleteStatus } : t
      )
    )

    try {
      // Send the update to the server
      const response = await axios.patch(
        `${API_BASE_URL}/api/usertasks/${taskId}/complete`,
        {
          isComplete: newIsCompleteStatus,
          userId: kidId,
        }
      )
      console.log('Task updated successfully:', response.data)

      // Response could include the updated task data and the new user points
      const updatedTaskData = response.data

      // Update the task in the state with the latest data from the server if necessary
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, ...updatedTaskData.task } : t
        )
      )

      // Assuming you have a function or state to update user points
      // updatePoints(updatedTaskData.userPoints); // This is a hypothetical function

      console.log('Task updated successfully:', updatedTaskData)

      if (response.data && response.data.user && response.data.user.points) {
        updateKidPoints(response.data.user.points) // Update points in UserDashboard
      }
    } catch (error) {
      console.error('Failed to update task completion:', error)
      // Optionally revert the UI change if the server call fails
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t._id === task._id ? { ...t, isComplete: !newIsCompleteStatus } : t
        )
      )
    }
  }
  const onToggleDaily = async (taskId, newDailyStatus) => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/api/usertasks/${taskId}/toggledaily`,
        {
          daily: newDailyStatus,
        }
      )
      console.log('Task daily status updated successfully:', response.data)

      // Update local state to reflect new daily status
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId ? { ...task, daily: newDailyStatus } : task
        )
      )
    } catch (error) {
      console.error('Failed to toggle daily status:', error)
    }
  }

  const confirmDeleteTask = async () => {
    console.log('Confirming delete for task:', taskToDelete)
    if (!taskToDelete) return

    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/usertasks/${taskToDelete}`
      )
      if (response.status === 204) {
        console.log('Task deleted successfully')
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task._id !== taskToDelete)
        )
        setIsDeleteModalOpen(false)
      }
    } catch (error) {
      console.error('Failed to delete task:', error)
      setIsDeleteModalOpen(false)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='max-w-8xl mx-auto px-2 sm:px-6 lg:px-4'>
      {tasks.map((task) => (
        <UserTask
          key={task._id}
          task={task}
          onToggleComplete={handleToggleComplete}
          onToggleDaily={onToggleDaily}
          onDeleteTask={() => openDeleteModal(task._id)}
        />
      ))}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmDeleteTask}
        />
      )}
    </div>
  )
}

export default UserTableTasks

export default function DeleteConfirmationModal({
  isOpen,
  onClose,
  onConfirm,
}) {
  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center'>
      <div className='bg-white p-4 rounded-lg max-w-sm mx-auto'>
        <h3 className='text-lg font-semibold leading-6 text-gray-900'>
          Are you sure you want to delete this item?
        </h3>
        <div className='mt-4 flex justify-end space-x-3'>
          <button
            onClick={onClose}
            className='inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm() // Assuming onConfirm will handle deletion and logging
              onClose() // Close the modal after confirming
            }}
            className='inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2'>
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'
import { useNavigate } from 'react-router-dom'

export default function BadgesItems() {
  const [badges, setBadges] = useState([])

  const getImageUrl = (imageId) => {
    return `${API_BASE_URL}/api/images/${imageId}`
  }

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/badges`)
      .then((response) => {
        const fetchedBadges = response.data
          .map((badge) => ({
            ...badge,
          }))
          .sort((a, b) => a.pointsToGet - b.pointsToGet)
        setBadges(fetchedBadges)
      })
      .catch((error) =>
        console.error('There was an error fetching the badges:', error)
      )
  }, [])

  return (
    <div className='bg-white py-8'>
      <div className='mx-auto max-w-4xl px-6 text-center lg:px-8'>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
              <h1 className='text-3xl font-bold mb-4 tracking-tight text-gray-900 sm:text-4xl'>
                Badges
              </h1>
            </div>
          </div>
        </div>
      </div>

      <ul
        role='list'
        className='max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3'>
        {badges.map((badge) => (
          <li
            key={badge._id}
            className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow'>
            <div className='flex flex-1 flex-col p-8'>
              <img
                className='mx-auto h-48 w-48 flex-shrink-0 rounded-full'
                src={getImageUrl(badge.imageId)}
                alt={badge.name}
                onError={(e) =>
                  console.log('Failed to load image:', e.target.src)
                }
              />
              <h3 className='mt-6 text-sm font-medium text-gray-900'>
                {badge.name}
              </h3>
              <dl className='mt-1 flex flex-grow flex-col justify-between'>
                <dt className='sr-only'>Title</dt>
                <dd className='mt-3'>
                  <span className='inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'>
                    {badge.condition}
                  </span>
                </dd>
              </dl>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

import React from 'react'

const UserTaskItem = ({ task, onAcceptTask }) => {
  const { _id, title, point_price, category } = task

  return (
    <div className='flex items-center justify-between mb-2 bg-white p-4 shadow rounded-lg'>
      <div className='flex items-center'>
        <input
          type='checkbox'
          className='form-checkbox h-5 w-5'
          onChange={() => onAcceptTask(_id)}
        />
        <span className='ml-2'>{title}</span>
      </div>
      <div>
        <span className='text-red-600'>{point_price} points</span>
        <span className='ml-4'>{category}</span>
        <button
          className='ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded'
          onClick={() => onAcceptTask(_id)}>
          Accept
        </button>
      </div>
    </div>
  )
}

export default UserTaskItem

import React from 'react'
import './Layout.css'
import NavBar from '../NavBar'
import Footer from '../Footer'

// Pass the child props
export default function Layout({ children }) {
  return (
    <div>
      {/* display the child prop */}
      <NavBar />
      {children}
      <Footer />
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UpdateTask from './UpdateTask' // Make sure this path is correct
import AddTask from './AddTask' // Make sure this path is correct
import DeleteConfirmationModal from './DeleteConfirmationModal'
import Spinner from './Spinner'
import Badge from './Badge' // Make sure this path is correct
import { API_BASE_URL } from '../apiConfig'
import { toast } from 'react-toastify'

export default function TableTasks() {
  const [tasks, setTasks] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [selectedTaskId, setSelectedTaskId] = useState(null)
  const [updateTrigger, setUpdateTrigger] = useState(0)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [taskToDelete, setTaskToDelete] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })

  const sortedTasks = React.useMemo(() => {
    let sortableTasks = [...tasks]
    if (sortConfig !== null) {
      sortableTasks.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableTasks
  }, [tasks, sortConfig])

  const requestSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/tasks`)
      .then((response) => setTasks(response.data))
      .catch((error) => console.error('There was an error!', error))
  }, [])

  // Function to close the modal and reset selectedTaskId
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedTaskId(null) // Ensure the selected task ID is cleared when modal is closed
  }
  const handleCloseAddModal = () => {
    setIsAddModalOpen(false)
  }

  const fetchTasks = () => {
    setIsLoading(true)
    axios
      .get(`${API_BASE_URL}/api/tasks`)
      .then((response) => {
        setTasks(response.data)
        setIsLoading(false)
      })
      .catch((error) =>
        console.error('There was an error fetching tasks:', error)
      )
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTasks()
  }, [updateTrigger])

  const triggerUpdate = () => {
    setUpdateTrigger((prev) => prev + 1)
  }

  const logEvent = async (message) => {
    try {
      await axios.post(`${API_BASE_URL}/api/logs`, { message })
      console.log('Event logged successfully')
    } catch (error) {
      console.error('Error logging event:', error)
    }
  }

  const handleDeleteTask = async (taskId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/tasks/${taskId}`)
      console.log('Task deleted successfully')
      toast.success('Task deleted successfully')
      await logEvent(`A task was deleted`)
      fetchTasks()
      // Refresh tasks list or trigger update
    } catch (error) {
      console.error('Error deleting task:', error)
      toast.error('Failed to delete task: ' + error.message)
    }
  }
  if (isLoading) {
    return <Spinner /> // Render a spinner or any loading indicator
  }

  return (
    <div className='bg-white py-8'>
      <div className='mx-auto max-w-4xl px-6 text-center lg:px-8'>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='sm:flex sm:items-center'>
            <div className='sm:flex-auto'>
              <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                Tasks
              </h1>
            </div>
            <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
              <button
                onClick={() => {
                  setIsAddModalOpen(true)
                }}
                type='button'
                className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                Add Task
              </button>
            </div>
          </div>

          {isAddModalOpen && (
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center'>
              <div className='bg-white p-4 rounded-lg max-w-lg mx-auto'>
                <AddTask
                  onClose={handleCloseAddModal}
                  onUpdate={triggerUpdate}
                />
              </div>
            </div>
          )}

          <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                        onClick={() => requestSort('title')}>
                        Title
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        onClick={() => requestSort('description')}>
                        Description
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                        onClick={() => requestSort('categoryName')}>
                        Category
                      </th>
                      <th
                        scope='col'
                        className='px-3 py-3.5 text-center text-sm font-semibold text-gray-900'
                        onClick={() => requestSort('point_price')}>
                        Points
                      </th>

                      <th
                        scope='col'
                        className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
                        <span className='sr-only'>Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200'>
                    {sortedTasks.map((task) => (
                      <tr
                        key={task.id}
                        onDoubleClick={() => {
                          setTaskToDelete(task.id)
                          setIsDeleteModalOpen(true)
                        }}>
                        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-xl text-left font-medium text-gray-900 sm:pl-0'>
                          {task.title}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-large text-left text-gray-500'>
                          {task.description}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-large text-left text-gray-500'>
                          <Badge label={task.categoryName} />
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-xl text-red-500'>
                          {task.point_price}
                        </td>

                        <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                          <button
                            onClick={() => {
                              setSelectedTaskId(task.id) // Set the selected task ID
                              setIsModalOpen(true) // Open the modal
                            }}
                            className='text-indigo-600 hover:text-indigo-900'>
                            Edit
                            <span className='sr-only'>, {task.title}</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {isModalOpen && selectedTaskId && (
                  <div className='fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center'>
                    <div className='bg-white p-4 rounded-lg max-w-lg mx-auto'>
                      <UpdateTask
                        taskId={selectedTaskId}
                        onClose={handleCloseModal}
                        onUpdate={triggerUpdate}
                      />
                    </div>
                  </div>
                )}
                {isAddModalOpen && (
                  <AddTask
                    onClose={handleCloseAddModal}
                    onUpdate={triggerUpdate}
                  />
                )}
                {isDeleteModalOpen && (
                  <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={() => handleDeleteTask(taskToDelete)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

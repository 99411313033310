import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import TableTasks from '../components/TableTasks'

const TasksStore = () => {
  useEffect(() => {
    document.title = 'Tasks'
  })

  return (
    <Layout>
      <TableTasks />
    </Layout>
  )
}

export default TasksStore

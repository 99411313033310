// UserTask.jsx
import React from 'react'
import { MdRecordVoiceOver, MdEventRepeat } from 'react-icons/md'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'

const UserTask = ({ task, onToggleComplete, onToggleDaily, onDeleteTask }) => {
  const {
    _id,
    taskId: { title, description, point_price },
    isComplete,
    daily,
  } = task

  const handleVoiceOver = async (text) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/tts?text=${description}`,
        {
          responseType: 'blob', // Important: handle the response as a blob since it's an audio file
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const audio = new Audio(url)
      audio.play()
    } catch (error) {
      console.error('Error fetching audio:', error)
    }
  }

  const handleCheckboxChange = () => {
    onToggleComplete(task)
  }

  const toggleDailyStatus = (e) => {
    e.stopPropagation() // Prevent triggering onDeleteTask when clicking the button
    onToggleDaily(_id, !daily)
  }

  return (
    <div
      className='flex items-center justify-between mb-2 bg-white p-4 shadow rounded-lg'
      onDoubleClick={() => onDeleteTask(_id)}>
      {' '}
      {/* Corrected here */}
      <div className='flex items-center'>
        <input
          type='checkbox'
          className='form-checkbox h-5 w-5'
          checked={isComplete}
          onChange={handleCheckboxChange}
        />
        <span
          className={`ml-2 ${isComplete ? 'text-red-600 line-through' : ''}`}>
          {title}
        </span>
      </div>
      <div>
        <span className='text-red-600'>+ {point_price} points</span>
        <button
          className={`ml-4 font-bold py-1 px-2 rounded ${
            daily
              ? 'bg-green-500 hover:bg-green-700'
              : 'bg-blue-500 hover:bg-blue-700'
          } text-white`}
          onClick={toggleDailyStatus}>
          <MdEventRepeat />
        </button>
        <button
          className='ml-4 bg-orange-500 hover:bg-orange-700 text-white font-bold py-1 px-2 rounded'
          onClick={handleVoiceOver}>
          <MdRecordVoiceOver />
        </button>
      </div>
    </div>
  )
}

export default UserTask

// Spinner.js
import React from 'react'
import './Spinner.css' // Assuming you have corresponding CSS

const Spinner = () => (
  <div className='spinner-overlay'>
    <div className='spinner-container' />
  </div>
)

export default Spinner

//file: src/pages/tasks.js
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import TableRewards from '../components/TableRewards'
const RewardList = () => {
  useEffect(() => {
    document.title = 'Rewards List'
  })
  return (
    <Layout>
      <TableRewards />
    </Layout>
  )
}
export default RewardList

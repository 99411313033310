// EventPanel.jsx
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'

function EventPanel() {
  const [logs, setLogs] = useState([])

  useEffect(() => {
    fetchLogs()
  }, [])

  const fetchLogs = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/logs`)
      setLogs(response.data)
    } catch (error) {
      console.error('Error fetching logs:', error)
    }
  }

  return (
    <div className='absolute right-0 top-0 mt-14 mr-4 w-96 bg-white shadow-xl rounded-lg p-4 z-50'>
      <h2 className='text-lg font-semibold'>Events Log</h2>
      <ul>
        {logs.map((log, index) => (
          <li key={index} className='mt-2'>
            <span className='font-medium'>{log.message}</span> -{' '}
            <span className='text-gray-500'>
              {new Date(log.timestamp).toLocaleString()}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default EventPanel

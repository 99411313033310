import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UserTaskItem from './UserTaskItem'
import { API_BASE_URL } from '../apiConfig'

const UserTasksList = ({ kidId }) => {
  const [tasks, setTasks] = useState([])
  const [selectedTasks, setSelectedTasks] = useState(new Set())

  useEffect(() => {
    // Fetch all available tasks
    axios
      .get(`${API_BASE_URL}/api/tasks`)
      .then((response) => setTasks(response.data))
      .catch((error) => console.error('Failed to fetch tasks:', error))
  }, [])

  const handleToggleComplete = (task) => {
    // Toggling task selection for acceptance
    const newSelectedTasks = new Set(selectedTasks)
    if (selectedTasks.has(task._id)) {
      newSelectedTasks.delete(task._id)
    } else {
      newSelectedTasks.add(task._id)
    }
    setSelectedTasks(newSelectedTasks)
  }

  const handleAcceptTasks = () => {
    // API call to assign selected tasks to the user
    axios
      .post(`${API_BASE_URL}/api/usertasks/bulk`, {
        userId: kidId,
        taskIds: Array.from(selectedTasks),
      })
      .then(() => {
        console.log('Tasks added successfully')
        // Possibly close the modal here or refresh the task list
      })
      .catch((error) => {
        console.error('Failed to assign tasks:', error)
      })
  }

  const onAcceptTask = (taskId) => {
    console.log(`Accepting task with ID: ${taskId}`)
    // Example of how you might handle accepting a task
    axios
      .post(`${API_BASE_URL}/api/usertasks`, { userId: kidId, taskId })
      .then(() =>
        console.log(`Task ${taskId} added successfully for user ${kidId}`)
      )
      .catch((error) => console.error('Failed to assign task:', error))
  }

  const handleTaskSelection = (taskId) => {
    const newSelectedTasks = new Set(selectedTasks)
    if (newSelectedTasks.has(taskId)) {
      newSelectedTasks.delete(taskId)
    } else {
      newSelectedTasks.add(taskId)
    }
    setSelectedTasks(newSelectedTasks)
  }

  const handleBulkAddTasks = async () => {
    const taskIds = Array.from(selectedTasks) // Convert Set to Array
    if (taskIds.length > 0) {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/api/usertasks/bulk`,
          {
            userId: kidId,
            taskIds: taskIds,
          }
        )
        console.log('Bulk tasks added successfully:', response.data)
        setSelectedTasks(new Set()) // Clear selection after adding
      } catch (error) {
        console.error('Failed to assign bulk tasks:', error)
      }
    }
  }

  return (
    <div className='max-w-8xl mx-auto px-2 sm:px-6 lg:px-4'>
      {tasks.map((task) => (
        <UserTaskItem
          key={task._id}
          task={task}
          onToggleComplete={() => handleToggleComplete(task)}
          onAcceptTask={onAcceptTask}
        />
      ))}
      <div className='flex justify-end space-x-3 mt-4'>
        <button
          onClick={handleBulkAddTasks}
          className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>
          Accept Selected
        </button>
      </div>
    </div>
  )
}

export default UserTasksList

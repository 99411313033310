import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { IconContext } from 'react-icons'
import { API_BASE_URL } from '../apiConfig'
import confetti from 'https://esm.run/canvas-confetti@1'
import { useNavigate } from 'react-router-dom'

import { FiPlusSquare, FiMinusSquare } from 'react-icons/fi'
import maryImage from '../images/mary.png'
import gregoryImage from '../images/gregory.png'
import alexImage from '../images/alex.png'
import blueyImage from '../images/bluey.png'
import spidermanImage from '../images/spiderman.png'
import thorImage from '../images/thor.png'

const imageMap = {
  maryImage,
  gregoryImage,
  alexImage,
  blueyImage,
  spidermanImage,
  thorImage,
}

export default function Team() {
  const [people, setPeople] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/kids`)
      .then((response) => {
        const fetchedKids = response.data
          .map((kid) => ({
            ...kid,
            imageUrl: imageMap[kid.imageUrl],
            avatar: imageMap[kid.avatar],
          }))
          .sort((a, b) => a.id - b.id)
        setPeople(fetchedKids)
      })
      .catch((error) =>
        console.error('There was an error fetching the kids:', error)
      )
  }, [])

  const updatePoints = async (kidId, operation, event) => {
    const updatedPeople = people.map((person) => {
      if (person._id === kidId) {
        const newPoints =
          operation === 'increment' ? person.points + 1 : person.points - 1

        const logMessage = `${person.name} ${
          operation === 'increment' ? 'added' : 'subtracted'
        } 1 point`
        saveEventLog(logMessage)

        // Optionally handle confetti for increment operation
        if (operation === 'increment') {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6, x: event.clientX / window.innerWidth },
          })
        }

        // Update points in the backend
        axios
          .put(`${API_BASE_URL}/api/kids/${kidId}`, { points: newPoints })
          .then(() => {
            // On success, update the state
            setPeople((prev) =>
              prev.map((kid) =>
                kid._id === kidId ? { ...kid, points: newPoints } : kid
              )
            )
          })
          .catch((error) => console.error('Failed to update points:', error))

        // Do not wait for the API call to update the UI
        return { ...person, points: newPoints }
      }
      return person
    })

    setPeople(updatedPeople)
  }

  const saveEventLog = async (message, timestamp = new Date()) => {
    try {
      await axios.post(`${API_BASE_URL}/api/logs`, { message, timestamp })
      console.log('Event log saved successfully')
    } catch (error) {
      console.error('Error saving event log:', error)
    }
  }

  const handleKidClick = (kidName) => {
    navigate(`/dashboard/${kidName}`) // Navigate to the UserDashboard of the clicked kid
  }

  return (
    <div className='bg-white py-4'>
      <div className='mx-auto max-w-7xl px-6 text-center lg:px-8'>
        <div className='mx-auto max-w-2xl'>
          <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Our kids
          </h1>
        </div>
        <ul
          role='list'
          className='mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
          {people.map((person) => (
            <li key={person.name}>
              <img
                className='mx-auto h-56 w-56 rounded-full xl:h-64 xl:w-64'
                src={person.imageUrl}
                alt={`${person.name}`}
                onClick={() => handleKidClick(person._id)}
              />
              <h1 className='mt-6 text-3xl font-semibold leading-7 tracking-tight text-gray-900'>
                {person.name}
              </h1>
              <h1 className='text-4xl mt-5 mb-5 leading-6 text-red-600'>
                Points: <strong>{person.points}</strong>
              </h1>
              <h1 className='text-4xl mt-5 mb-5 leading-6 text-green-600'>
                USD: <strong>${(person.points / 10).toFixed(2)}</strong>
              </h1>
              <IconContext.Provider
                value={{
                  color: 'grey',
                  size: '3em',
                  className: 'global-class-name',
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                  }}>
                  <FiMinusSquare
                    onClick={() => updatePoints(person._id, 'decrement')}
                  />
                  <img
                    className='h-16 w-16 rounded-full'
                    src={person.avatar}
                    alt={`${person.name}'s avatar`}
                  />
                  <FiPlusSquare
                    onClick={(event) =>
                      updatePoints(person._id, 'increment', event)
                    }
                  />
                </div>
              </IconContext.Provider>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

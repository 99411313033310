import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'
import { toast } from 'react-toastify'

export default function AddTask({ onClose, onUpdate }) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [pointPrice, setPointPrice] = useState('1')
  const [categories, setCategories] = useState([])

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/categories`)
      .then((response) => {
        setCategories(response.data)
        if (response.data.length > 0) {
          setCategoryId(response.data[0].id.toString()) // Set default category id
        }
      })
      .catch((error) => console.error('Failed to fetch categories:', error))
  }, [])

  const handleAIGeneration = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/aitask`)
      console.log('API Response:', response.data) // Log the string to see what you're receiving

      // Assuming response.data is a string that contains JSON, parse it:
      const parsedData = JSON.parse(response.data)
      console.log('parsedData:', parsedData)
      if (parsedData) {
        const { title, description, categoryId, pointPrice } = parsedData
        setTitle(title || '')
        setDescription(description || '')
        setCategoryId(categoryId ? categoryId.toString() : '')
        setPointPrice(pointPrice ? pointPrice.toString() : '1')
      } else {
        console.error('No task data received')
        toast.error('Failed to generate task: No data received')
      }
      console.log('title:', title)
      console.log('desc:', description)
      console.log('category:', categoryId)
      console.log('point_price:', pointPrice)
    } catch (error) {
      console.error('Failed to generate task:', error)
      toast.error(`Failed to generate task: ${error.message}`)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .post(`${API_BASE_URL}/api/tasks`, {
        title,
        description,
        categoryId: Number(categoryId),
        point_price: Number(pointPrice),
      })
      .then(() => {
        toast.success('Task added successfully')
        console.log('Task added successfully')
        // Here is where you call logEvent
        logEvent(`A new task titled '${title}' was added.`)
        onClose()
        onUpdate()
      })
      .catch((error) => {
        console.error('Failed to add task:', error)
        toast.error('Failed to add task: ' + error.message)
      })
  }

  const logEvent = async (message) => {
    try {
      await axios.post(`${API_BASE_URL}/api/logs`, { message })
      console.log('Event logged successfully')
    } catch (error) {
      console.error('Error logging event:', error)
    }
  }

  return (
    <div className='bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='text-lg font-semibold leading-6 text-gray-900'>
          Add New Task
        </h3>
        <form className='space-y-4' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='title'
              className='block text-sm text-left font-medium text-gray-700'>
              Title
            </label>
            <input
              type='text'
              name='title'
              id='title'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div>
            <label
              htmlFor='description'
              className='block text-sm text-left font-medium text-gray-700'>
              Description
            </label>
            <textarea
              name='description'
              id='description'
              required
              rows={4}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div>
            <label
              htmlFor='category'
              className='block text-sm text-left font-medium text-gray-700'>
              Category
            </label>
            <select
              name='category'
              id='category'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor='pointPrice'
              className='block text-sm text-left font-medium text-gray-700'>
              Point Price
            </label>
            <input
              type='number'
              name='pointPrice'
              id='pointPrice'
              min={1}
              max={10}
              step={1}
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setPointPrice(e.target.value)}
              value={pointPrice}
            />
          </div>
          <div className='flex justify-end space-x-3'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={onClose}>
              Close
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
              onClick={handleAIGeneration}>
              AI
            </button>
            <button
              type='submit'
              className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const colorMap = {
  Education: 'text-purple-700 bg-purple-50 ring-purple-600/20',
  Games: 'text-red-700 bg-red-50 ring-red-600/20',
  Creative: 'text-pink-700 bg-pink-50 ring-pink-600/20',
  Activity: 'text-blue-700 bg-blue-50 ring-blue-600/20',
  Social: 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
  Skill: 'text-green-700 bg-green-50 ring-green-600/20',
  Chores: 'text-gray-700 bg-gray-50 ring-gray-600/20',
  Health: 'text-indigo-700 bg-indigo-50 ring-indigo-600/20',
  Other: 'text-orange-700 bg-orange-50 ring-orange-600/20',
  default: 'text-gray-700 bg-gray-50 ring-gray-600/20', // Default colors
}

export default function Badge({ label }) {
  const colorClass = colorMap[label] || colorMap.default

  return (
    <div
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${colorClass}`}>
      {label}
    </div>
  )
}

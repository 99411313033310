import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import UserDashboard from '../components/UserDashboard'

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard'
  })

  return (
    <Layout>
      <UserDashboard />
    </Layout>
  )
}

export default Dashboard

import React, { useState } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../apiConfig'
import { toast } from 'react-toastify'

export default function AddReward({ onClose, onUpdate }) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [pointCost, setPointCost] = useState(1)

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .post(`${API_BASE_URL}/api/rewards`, {
        title,
        description,
        point_cost: pointCost,
      })
      .then(() => {
        console.log('Reward added successfully')
        toast.success('Reward added successfully')
        logEvent(`A new reward titled '${title}' was added.`)
        onClose()
        onUpdate()
      })
      .catch((error) => {
        console.error('Failed to add reward:', error)
        toast.error('Failed to add reward: ' + error.message)
      })
  }

  const logEvent = async (message) => {
    try {
      await axios.post(`${API_BASE_URL}/api/logs`, { message })
      console.log('Event logged successfully')
    } catch (error) {
      console.error('Error logging event:', error)
    }
  }

  return (
    <div className='bg-white shadow sm:rounded-lg'>
      <div className='px-4 py-5 sm:p-6'>
        <h3 className='text-lg font-semibold leading-6 text-gray-900'>
          Add New Reward
        </h3>
        <form className='space-y-4' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='title'
              className='block text-sm text-left font-medium text-gray-700'>
              Title
            </label>
            <input
              type='text'
              name='title'
              id='title'
              required
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor='description'
              className='block text-sm text-left font-medium text-gray-700'>
              Description
            </label>
            <textarea
              name='description'
              id='description'
              required
              rows={4}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor='pointPrice'
              className='block text-sm text-left font-medium text-gray-700'>
              Point Cost
            </label>
            <input
              type='number'
              name='pointPrice'
              id='pointPrice'
              min={1}
              max={100}
              step={1}
              required
              defaultValue={1}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
              onChange={(e) => setPointCost(e.target.value || 1)}
            />
          </div>
          <div className='flex justify-end space-x-3'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={onClose}>
              Close
            </button>
            <button
              type='submit'
              className='inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import RewardList from './pages/Rewards'
import BadgesList from './pages/Badges'
import TasksStore from './pages/Tasks'
import KidsCards from './pages/Kids'
import Dashboard from './pages/Dashboard'
import NotFoundPage from './pages/NotFoundPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AppRoutes = () => {
  return (
    <Router>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<KidsCards />} />
        <Route path='/tasks' element={<TasksStore />} />
        <Route path='/rewards' element={<RewardList />} />
        <Route path='/badges' element={<BadgesList />} />
        <Route path='/dashboard/:kidId' element={<Dashboard />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  )
}

export default AppRoutes
